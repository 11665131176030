import { useEffect, useState } from "react";
import {
	IDescription,
	ISocialNetworksType,
	ITask,
	ITaskType,
} from "../../types/admin";
import styles from "./EditTask.module.scss";
import { fetchBroadcast, fetchUpdateTask } from "../../api/admin";
import { toast } from "react-toastify";
import cl from "classnames";
import { modal } from "../../../common/ui/Modal/ModalConnector";
import DatePicker from "react-datepicker";
import { parseISOString } from "../../utils/parseISOtoString";
import { differenceBetweenDates } from "../../utils/formatDate";
import { checkLink } from "../../utils/checkLink";

interface IEditTask {
	task: ITask;
	tasks: ITask[];
	getTasks: Function;
}

export const EditTask: React.FC<IEditTask> = ({ task, tasks, getTasks }) => {
	const [name, setName] = useState<string>(task.name);
	const [description, setDescription] = useState<IDescription>(
		task.description
	);
	const [reward, setReward] = useState<string>(task.reward);
	const [active, setActive] = useState<boolean>(task.active);
	const [password, setPassword] = useState<string>("");
	const [start, setStart] = useState<Date | null | undefined>(
		parseISOString(task.start)
	);
	const [end, setEnd] = useState<Date | null | undefined>(
		parseISOString(task.end)
	);
	const [duration, setDuration] = useState<string>(
		task.description.type === ITaskType.general
			? "1 year"
			: task.description.type === ITaskType.daily
			? "1 day"
			: ""
	);
	const [isOpenSocialNetworksType, setIsOpenSocialNetworksType] =
		useState<boolean>(false);
	const [currentSocialNetworksType, setCurrentSocialNetworksType] =
		useState<ISocialNetworksType>(task.description.social_network_type);
	const [isOpenTaskType, setIsOpenTaskType] = useState<boolean>(false);
	const [currentTaskType, setCurrentTaskType] = useState<ITaskType>(
		task.description.type
	);
	const [link, setLink] = useState<string>(task.description.link);
	const [messageEn, setMessageEn] = useState<string>("");
	const [messageRu, setMessageRu] = useState<string>("");
	const [isSendMessage, setIsSendMessage] = useState<boolean>(false);
	const [secretPhrase, setSecretPhrase] = useState<string>("");

	useEffect(() => {
		setDescription({
			social_network_type: description.social_network_type,
			link: description.link,
			type: description.type,
			secretPhrase: secretPhrase.length > 0,
		});
		console.log(description);
	}, [secretPhrase]);

	const handleUpdateTask = async (
		name: string,
		description: IDescription,
		reward: string,
		active: boolean,
		start: string | null,
		end: string | null,
		password: string,
		message: string,
		secretPhrase: string,
		messageEn: string,
		messageRu: string
	) => {
		console.log("hello", description);
		if (!checkLink(description.link, description.social_network_type, tasks)) {
			toast.error("Wrong link!");
			return;
		}
		try {
			const res = await fetchUpdateTask(
				name,
				description,
				reward,
				active,
				start,
				end,
				password,
				task.id,
				secretPhrase
			);
			if (res.data) {
				toast.success("Success!");
				await getTasks();
				modal.close();
				if (isSendMessage) {
					await sendMessage(messageEn, messageRu, password);
				}
			} else {
				toast.error("Wrong password!");
			}
		} catch (e) {
			toast.error(JSON.stringify(e));
			console.log(e);
		}
	};

	const sendMessage = async (
		messageEn: string,
		messageRu: string,
		password: string
	) => {
		try {
			const res = await fetchBroadcast(
				{ en: messageEn, ru: messageRu },
				password
			);
			if (res.data) {
				toast.success("Message sent!");
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (description.type === ITaskType.custom && start && end) {
			let date = differenceBetweenDates(start, end);
			if (date.days > 0) {
				setDuration(`${date.days} days`);
			}
		}
	}, [start, end, task.start, task.start]);

	useEffect(() => {
		let resCurrentSocialNetworksType = "";
		if (currentSocialNetworksType === ISocialNetworksType.discord) {
			resCurrentSocialNetworksType = "Discord";
		} else if (currentSocialNetworksType === ISocialNetworksType.instagram) {
			resCurrentSocialNetworksType = "Instagram";
		} else if (currentSocialNetworksType === ISocialNetworksType.tiktok) {
			resCurrentSocialNetworksType = "TikTok";
		} else if (currentSocialNetworksType === ISocialNetworksType.twitter) {
			resCurrentSocialNetworksType = "Twitter";
		} else if (currentSocialNetworksType === ISocialNetworksType.youtube) {
			resCurrentSocialNetworksType = "YouTube";
		} else if (currentSocialNetworksType === ISocialNetworksType.custom) {
			resCurrentSocialNetworksType = "Custom";
		}
		setMessageEn(`✔️ New task is live: ${resCurrentSocialNetworksType}!`);
		setMessageRu(`✔️ Новое задание доступно: ${resCurrentSocialNetworksType}!`);
	}, [currentSocialNetworksType]);

	const arrow = (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.707 10.707L12 16.414L6.29303 10.707L7.70703 9.293L12 13.586L16.293 9.293L17.707 10.707Z"
				fill="white"
			/>
		</svg>
	);

	return (
		<div className={styles.content}>
			<div className={styles.block}>
				<div className={styles.disabled}>
					<div className={styles.title}>id</div>
					<input value={task.id} className={styles.input} />
				</div>
				<div>
					<div className={styles.title}>name</div>
					<input
						value={name}
						onChange={(ev) => setName(ev.target.value)}
						className={styles.input}
					/>
				</div>
				<div>
					<div className={styles.title}>type of social network</div>
					<div className={styles.select}>
						<div
							className={styles.selectBc}
							onClick={() => {
								setIsOpenTaskType(false);
								setIsOpenSocialNetworksType(!isOpenSocialNetworksType);
							}}
						>
							<div className={styles.selectBcValue}>
								{currentSocialNetworksType}
							</div>
							<div
								className={cl(styles.selectBcArrow, {
									[styles.active]: isOpenSocialNetworksType,
								})}
							>
								{arrow}
							</div>
						</div>
						{isOpenSocialNetworksType && (
							<div className={styles.optionsSelectBc}>
								{Object.values(ISocialNetworksType).map((option) => {
									return (
										<div
											className={styles.optionSelectBc}
											onClick={() => {
												setCurrentSocialNetworksType(option);
												setIsOpenSocialNetworksType(!isOpenSocialNetworksType);
												setDescription({
													social_network_type: option,
													link: description ? description.link : "",
													type: description
														? description.type
														: ITaskType.custom,
													secretPhrase: secretPhrase.length > 0,
												});
											}}
										>
											<div>{option}</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
				<div>
					<div className={styles.title}>link</div>
					<input
						value={link}
						onChange={(ev) => {
							setDescription({
								social_network_type: description
									? description.social_network_type
									: ISocialNetworksType.custom,
								link: ev.target.value,
								type: description ? description.type : ITaskType.custom,
								secretPhrase: secretPhrase.length > 0,
							});
							setLink(ev.target.value);
						}}
						className={styles.input}
					/>
				</div>
				<div>
					<div className={styles.title}>task type</div>
					<div
						className={styles.select}
						style={{ zIndex: isOpenSocialNetworksType ? "0" : "1" }}
					>
						<div
							className={styles.selectBc}
							onClick={() => {
								setIsOpenSocialNetworksType(false);
								setIsOpenTaskType(!isOpenTaskType);
							}}
						>
							<div className={styles.selectBcValue}>{currentTaskType}</div>
							<div
								className={cl(styles.selectBcArrow, {
									[styles.active]: isOpenTaskType,
								})}
							>
								{arrow}
							</div>
						</div>
						{isOpenTaskType && (
							<div className={styles.optionsSelectBc}>
								{Object.values(ITaskType).map((option) => {
									return (
										<div
											className={styles.optionSelectBc}
											onClick={() => {
												setCurrentTaskType(option);
												setIsOpenTaskType(!isOpenTaskType);
												setDescription({
													social_network_type: description
														? description.social_network_type
														: ISocialNetworksType.custom,
													link: description ? description.link : "",
													type: option,
													secretPhrase: secretPhrase.length > 0,
												});
												if (option === ITaskType.general) {
													setStart(new Date());
													let nextYear = new Date();
													nextYear.setFullYear(nextYear.getFullYear() + 1);
													setEnd(nextYear);
													setDuration("1 year");
												}
												if (option === ITaskType.daily) {
													setStart(new Date());
													let tomorrow = new Date();
													tomorrow.setDate(tomorrow.getDate() + 1);
													setEnd(tomorrow);
													setDuration("1 day");
												}
											}}
										>
											<div>{option}</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
				<div
					className={cl({
						[styles.disabled]:
							currentTaskType === ITaskType.daily ||
							currentTaskType === ITaskType.general,
					})}
				>
					<div className={styles.title}>start</div>
					<DatePicker
						selected={start}
						onChange={(date) => setStart(date)}
						className={styles.input}
					/>
				</div>
				<div
					className={cl({
						[styles.disabled]:
							currentTaskType === ITaskType.daily ||
							currentTaskType === ITaskType.general,
					})}
				>
					<div className={styles.title}>end</div>
					<DatePicker
						selected={end}
						onChange={(date) => setEnd(date)}
						className={styles.input}
					/>
				</div>
			</div>
			<div className={styles.block}>
				<div className={styles.disabled}>
					<div className={styles.title}>duration</div>
					<input value={duration} className={styles.input} />
				</div>
				<div>
					<div className={styles.title}>reward</div>
					<input
						value={reward}
						onChange={(ev) => setReward(ev.target.value)}
						className={styles.input}
					/>
				</div>
				<div style={{ display: "flex", gap: "14px" }}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "start",
						}}
					>
						<div className={styles.title}>active</div>
						<input
							className={styles.input}
							value={active as unknown as number}
							onChange={(e) => setActive(!active)}
							type="checkbox"
							checked={active ? true : false}
						/>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "start",
						}}
					>
						<div className={styles.title}>send message?</div>
						<input
							className={styles.input}
							value={isSendMessage as unknown as number}
							onChange={(e) => setIsSendMessage(!isSendMessage)}
							type="checkbox"
							checked={isSendMessage ? true : false}
						/>
					</div>
				</div>
				<div>
					<div className={styles.title}>password</div>
					<input
						value={password}
						onChange={(ev) => setPassword(ev.target.value)}
						className={styles.input}
					/>
				</div>
				<div>
					<div className={styles.title}>message en</div>
					<textarea
						value={messageEn}
						onChange={(ev) => {
							setMessageEn(ev.target.value);
						}}
						className={cl(styles.input, styles.message)}
					/>
				</div>
				<div>
					<div className={styles.title}>message ru</div>
					<textarea
						value={messageRu}
						onChange={(ev) => {
							setMessageRu(ev.target.value);
						}}
						className={cl(styles.input, styles.message)}
					/>
				</div>
				<div>
					<div className={styles.title}>secret phrase</div>
					<input
						value={secretPhrase}
						onChange={(ev) => {
							setSecretPhrase(ev.target.value.toLocaleLowerCase());
						}}
						className={styles.input}
					/>
				</div>
				<button
					onClick={() =>
						handleUpdateTask(
							name,
							description,
							reward,
							active,
							String(start?.toISOString()),
							String(end?.toISOString()),
							password,
							messageEn,
							secretPhrase,
							messageEn,
							messageRu
						)
					}
					className={styles.btn}
				>
					update task
				</button>
			</div>
		</div>
	);
};
