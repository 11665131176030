import { Routes, Route } from "react-router-dom";
import AdminPage from "../../modules/admin/ui/AdminPage/AdminPage";

export const AppRoutes = () => {
	return (
		<Routes>
			<Route path={"/"} element={<AdminPage />} />
		</Routes>
	);
};
