import { ISocialNetworksType, ITask } from "../types/admin";

export const checkLink = (
	link: string,
	currentSocialNetworksType: ISocialNetworksType,
	tasks: ITask[]
) => {
	console.log(link, currentSocialNetworksType);
	let find = tasks.find((i) => i.description.link === link);
	if (find) {
		return false;
	}
	if (
		currentSocialNetworksType === ISocialNetworksType.youtube &&
		!link.includes("youtube.com")
	) {
		return false;
	}
	if (
		currentSocialNetworksType === ISocialNetworksType.tiktok &&
		!link.includes("tiktok.com")
	) {
		return false;
	}
	if (
		currentSocialNetworksType === ISocialNetworksType.twitter &&
		!link.includes("x.com")
	) {
		return false;
	}
	if (
		currentSocialNetworksType === ISocialNetworksType.instagram &&
		!link.includes("instagram.com")
	) {
		return false;
	}
	if (
		currentSocialNetworksType === ISocialNetworksType.telegram &&
		!link.includes("t.me")
	) {
		return false;
	}
	if (
		currentSocialNetworksType === ISocialNetworksType.discord &&
		!link.includes("discord")
	) {
		return false;
	}
	return true;
};
