import { useState } from "react";
import { IPromocode } from "../../types/admin";
import styles from "./EditPromocode.module.scss";
import { fetchUpdatePromocode } from "../../api/admin";
import { toast } from "react-toastify";
import { modal } from "../../../common/ui/Modal/ModalConnector";
import DatePicker from "react-datepicker";
import { parseISOString } from "../../utils/parseISOtoString";

interface IEditPromocode {
	promocode: IPromocode;
	getPromocodes: Function;
}

export const EditPromocode: React.FC<IEditPromocode> = ({
	promocode,
	getPromocodes,
}) => {
	const [value, setValue] = useState<string>(promocode.value);
	const [reward, setReward] = useState<string>(promocode.reward);
	const [active, setActive] = useState<boolean>(promocode.active);
	const [password, setPassword] = useState<string>("");
	const [start, setStart] = useState<Date | null | undefined>(
		parseISOString(promocode.start)
	);
	const [end, setEnd] = useState<Date | null | undefined>(
		parseISOString(promocode.end)
	);

	const handleUpdatePromocode = async (
		value: string,
		reward: string,
		active: boolean,
		start: string | null,
		end: string | null,
		password: string
	) => {
		try {
			const res = await fetchUpdatePromocode(
				value,
				reward,
				active,
				start,
				end,
				promocode.id,
				password
			);
			if (res.data) {
				toast.success("Success!");
				await getPromocodes();
				modal.close();
			} else {
				toast.error("Wrong password!");
			}
		} catch (e) {
			toast.error(JSON.stringify(e));
			console.log(e);
		}
	};

	return (
		<div className={styles.content}>
			<div className={styles.block}>
				<div className={styles.disabled}>
					<div className={styles.title}>id</div>
					<input value={promocode.id} className={styles.input} />
				</div>
				<div>
					<div className={styles.title}>value</div>
					<input
						value={value}
						onChange={(ev) => setValue(ev.target.value)}
						className={styles.input}
					/>
				</div>
				<div>
					<div className={styles.title}>start</div>
					<DatePicker
						selected={start}
						onChange={(date) => setStart(date)}
						className={styles.input}
					/>
				</div>
				<div>
					<div className={styles.title}>end</div>
					<DatePicker
						selected={end}
						onChange={(date) => setEnd(date)}
						className={styles.input}
					/>
				</div>
			</div>
			<div className={styles.block}>
				<div>
					<div className={styles.title}>reward</div>
					<input
						value={reward}
						onChange={(ev) => setReward(ev.target.value)}
						className={styles.input}
					/>
				</div>
				<div style={{ display: "flex", gap: "14px" }}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "start",
						}}
					>
						<div className={styles.title}>active</div>
						<input
							className={styles.input}
							value={active as unknown as number}
							onChange={(e) => setActive(!active)}
							type="checkbox"
							checked={active ? true : false}
						/>
					</div>
				</div>
				<div>
					<div className={styles.title}>password</div>
					<input
						value={password}
						onChange={(ev) => setPassword(ev.target.value)}
						className={styles.input}
					/>
				</div>
				<button
					onClick={() =>
						handleUpdatePromocode(
							value,
							reward,
							active,
							String(start?.toISOString()),
							String(end?.toISOString()),
							password
						)
					}
					className={styles.btn}
				>
					update promocode
				</button>
			</div>
		</div>
	);
};
